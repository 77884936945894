import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import academic_guidance from "../assets/images/feature/academic_guidance.png";
import arrival_orientation from "../assets/images/feature/arrival_orientation.png";
import career_guidance from "../assets/images/feature/career_guidance.png";
import suppport_applcation from "../assets/images/feature/suppport_applcation.png";
import Visa from "../assets/images/feature/Visa.png";
import tests from "../assets/images/feature/tests.png";
import financial from "../assets/images/feature/financial.png";
import college from "../assets/images/feature/college.png"
import "../assets/style/home.css";
import accounting from "../assets/images/course/accounting.svg"
import arts from "../assets/images/course/arts.svg"
import business from "../assets/images/course/business.svg"
import engineering from "../assets/images/course/engineering.svg"
import finance from "../assets/images/course/finance.svg"
import management from "../assets/images/course/management.svg"
import medicine from "../assets/images/course/medicine.svg"
import sciences from "../assets/images/course/sciences.svg"
import dataScience from "../assets/images/course/dataScience.svg"
import socialStudies from "../assets/images/course/socialStudies.svg"
import BANNER_AUSTRALIA from "../assets/images/banner/BANNER_AUSTRALIA.webp";
import usa from "../assets/images/country/usa.jpg";
import canada from "../assets/images/country/canada.jpg";
import uk from "../assets/images/country/uk.jpg";
import australia from "../assets/images/country/australia.jpg";
import { FaQuoteLeft } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import BANNER_CANADA from "../assets/images/banner/BANNER_CANADA.webp";
import BANNER_UK from "../assets/images/banner/BANNER_UK.webp";

import BANNER_USA from "../assets/images/banner/BANNER_USA.webp"
import homescreen from "../assets/video/homescreen.mp4"


const Home = () => {
    return (
        <>
            {/* <div className="home_main_banner pb-80"> */}
            {/* <Swiper
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Sah Education</title>
                <link rel="canonical" href="https://saheducation.co.uk/about" />
            </Helmet>
            <div className="home_main_banner pb-80">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={BANNER_AUSTRALIA} alt="" style={{ width: "100%" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={BANNER_CANADA} alt="" style={{ width: "100%" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={BANNER_UK} alt="" style={{ width: "100%" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={BANNER_USA} alt="" style={{ width: "100%" }} />
                    </SwiperSlide>

                </Swiper> */}


            <div className="video-container">
                {/* <div className="left-overlay"></div> */}
                <video autoPlay muted loop id="background-video">
                    <source src={homescreen} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="row">

                    <div className="col-lg-7 col-sm-12">
                        <div className="content">

                            <h1>Welcome to Sah Education</h1>
                            <p>A plan for every dream
                                The Most Comprehensive & Personalised Study Abroad Plan, suited for your Dreams. Obsessed with Student Success.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}


            {/* featured services */}
            <div className="featured_services pb-80 pt-80">
                <div className="container">
                    <h2>Featured Services</h2>

                    <div className="featured_service_area">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={career_guidance} alt="" />
                                    <h4>Career Guidance and Counselling</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={college} alt="" />
                                    <h4>College and University Selection</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={financial} alt="" />
                                    <h4>Financial Aid and Scholarship Guidance</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={Visa} alt="" />
                                    <h4>Assistance with Visas and Immigration
                                    </h4>
                                </div>

                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={academic_guidance} alt="" />
                                    <h4>Academic Guidance</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={tests} alt="" />
                                    <h4>Getting Ready for Tests</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={suppport_applcation} alt="" />
                                    <h4>Support for Applications</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="feature_card">
                                    <img src={arrival_orientation} alt="" />
                                    <h4>Arrival Pre-Orientation</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="featured_services pb-80 pt-80">
                <div className="container">
                    <h2>Explore Courses</h2>

                    <div className="featured_service_area">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={management} alt="" />
                                    </div>
                                    <h4>Management</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={business} alt="" />
                                    </div>
                                    <h4>Business</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={finance} alt="" />
                                    </div>
                                    <h4>Finance</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={engineering} alt="" />
                                    </div>
                                    <h4>Engineering
                                    </h4>
                                </div>

                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={accounting} alt="" />
                                    </div>
                                    <h4>Accounting</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={sciences} alt="" />
                                    </div>
                                    <h4>Science</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={socialStudies} alt="" />
                                    </div>
                                    <h4>Social Studies</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={dataScience} alt="" />
                                    </div>
                                    <h4>Data Science and Analytics</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={medicine} alt="" />
                                    </div>
                                    <h4>Medicine</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="course_card">
                                    <div className="course_icon">
                                        <img src={arts} alt="" />
                                    </div>
                                    <h4>Arts</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="top_country pb-80 pt-80">
                <div className="container">
                    <h2>Top Countries</h2>

                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <div className="country_card">
                                <img src={usa} alt="" style={{ width: "100%" }} />
                                <p className="text-center">Study in USA</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-12">
                            <div className="country_card">
                                <img src={uk} alt="" style={{ width: "100%" }} />
                                <p className="text-center">Study in UK</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="country_card">
                                <img src={australia} alt="" style={{ width: "100%" }} />
                                <p className="text-center">Study in Australia </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="country_card">
                                <img src={canada} alt="" style={{ width: "100%" }} />
                                <p className="text-center">Study in Canada</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="feedback_card pb-80 pt-80">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="contact_card">
                            <div className="form-container">
                                <h4>Contact Us</h4>

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" id="name" name="name" placeholder="Enter your name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" placeholder="Enter your email" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Mobile Number</label>
                                        <input type="number" id="number" name="number" placeholder="Enter your number" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea name="" id="message" rows="5" column=""></textarea>
                                    </div>
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="testimonial_card">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}

                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >

                                <SwiperSlide>
                                    <div className="testimonial_area">
                                        <p><FaQuoteLeft /></p>
                                        <p>SAH Education provided personalized guidance that helped me find the perfect university. Their expertise made the application process smooth and stress-free
                                        </p>
                                        <p>--Rohan</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="testimonial_area">
                                        <p><FaQuoteLeft /></p>
                                        <p>Excellent support from start to finish! SAH Education's consultants were knowledgeable and always available to answer my questions
                                        </p>
                                        <p>--Shweta</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="testimonial_area">
                                        <p><FaQuoteLeft /></p>
                                        <p>Highly recommend SAH Education for their professional and tailored advice. They truly care about student success and go the extra mile
                                        </p>
                                        <p>--Deepak</p>
                                    </div>
                                </SwiperSlide>

                            </Swiper>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home