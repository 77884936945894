import React, { useEffect, useState } from 'react'
import "../assets/style/navbar.css";
import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';

import { FiMinus, FiPlus } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { FaAngleDown, FaChevronUp } from 'react-icons/fa';
import { FaAngleUp } from 'react-icons/fa6';
import { IoIosSearch } from 'react-icons/io';
import australiaFlag from "../assets/images/flag/australiaFlag.jpg";
import canadaFlag from "../assets/images/flag/canadaFlag.jpg";

const Navbar = () => {

    const [isSticky, setSticky] = useState(false);

    const [isHovered, setHovered] = useState(false);
    const [isMenuVisible, setMenuVisible] = useState(false)

    const handleLinkClick = () => {
        setMenuVisible(false);
    };

    const handleMouseEnter = () => {
        setHovered(true);
        setMenuVisible(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
        setMenuVisible(false);
    };


    const getStudyinAbroad = async () => {
        console.log(process.env.REACT_APP_BASE_URL)
        let url = process.env.REACT_APP_BASE_URL;
        let response = await axios.get(url + `country?study_in_abroad=Yes`);


        console.log(response)
        return response?.data;
    };

    const { data: studyAbroad, isLoading: isloadingAbroad } = useQuery({
        queryKey: ["GetStudyInAbroad"],
        queryFn: () => getStudyinAbroad(),
    });

    // useEffect(() => {
    //     getStudyinAbroad()
    // }, [])


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <section className={isSticky ? "header sticky-form" : 'header'}>
            <nav>
                <div className="container">
                    <div className="main-header">
                        <div className="header-left">
                            <Link to="/">  <img src={logo} alt="" /></Link>
                        </div>

                        <div className="header-right">
                            <ul >
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link onMouseEnter={() => handleMouseEnter("Courses")}>Study Abroad</Link>
                                    {isMenuVisible ? <FaAngleUp /> : <FaAngleDown />}
                                    {isMenuVisible && (
                                        <ul className="submenu__list" onMouseLeave={handleMouseLeave}>
                                            {studyAbroad?.data.map((row, index) => (
                                                <li>
                                                    <img src="" alt="" />
                                                    <Link to={`/study-abroad/${row?.slug}`}>Study in {row?.name}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                                <li><Link to="/career">Career</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>


                        </div>

                        <div className="header-right">
                            <ul>
                                <li><div className="search_box">
                                    <IoIosSearch fontSize="30px" />
                                </div></li>

                                <li>
                                    <Link to="/contact">
                                        <button className="app_btn" >
                                            Free Councelling
                                        </button>

                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </section>
    )
}

export default Navbar