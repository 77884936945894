import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { IoMdCall } from 'react-icons/io'
import { IoMailUnreadOutline } from 'react-icons/io5'
import { MdContacts } from 'react-icons/md'
import { Link } from 'react-router-dom'
import "../assets/style/callaction.css"

const CallToAction = () => {

    // Function to handle click on "Enquiry" button
    function handleEnquiryClick() {
        // Check if the screen width is below the responsive breakpoint (e.g., 768px for mobile/tablet)
        if (window.innerWidth <= 768) {
            // Open the modal
            document.getElementById("enquiryModal").style.display = "block";
        }
    }


    function handleMailModal() {
        // Check if the screen width is below the responsive breakpoint (e.g., 768px for mobile/tablet)
        if (window.innerWidth <= 768) {
            // Open the mail modal
            document.getElementById("enquiryMailModal").style.display = "block";
        }
    }

    // Function to close the modal
    function closeModal() {
        document.getElementById("enquiryModal").style.display = "none";
        document.getElementById("enquiryMailModal").style.display = "none";
    }
    return (
        <>
            <div class="foot-fixed"><div class="row align-items-center">

                <div class="col-lg-3 col-sm-3"><Link to="tel:+447508655533"><IoMdCall fontSize="20px" color='#000' /><div style={{ color: "#000" }}>Call </div></Link></div>
                <div class="col-lg-3 col-sm-3"><Link to="https://wa.me/+447508655533"><FaWhatsapp fontSize="20px" style={{ color: "#000" }} />
                    <div style={{ color: "#000" }}>Chat</div>
                </Link></div>
                <div className="col-lg-3 col-sm-3">
                    <Link to="mailto:info@saheducation.co.uk">
                        <IoMailUnreadOutline fontSize="20px" />
                        <div>Mail</div>

                    </Link>
                </div>
                <div class="col-lg-3 col-sm-3" onClick={handleEnquiryClick} ><MdContacts fontSize="20px" />
                    <div>Enquiry</div></div>

            </div></div>


            {/* Enquiry Modal */}
            <div id="enquiryModal" style={{ display: "none" }} className="footer_fixed modal">
                <div className="modal-content">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="contact__page_card">
                        <h4>Contact Form</h4>

                        <div class="p-a30 bg-gray">

                            <form class="cons-contact-form" method="post" action="phpmailer/mail.php">

                                <div class="row">

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                                <input name="username" type="text" required="" class="form-control" placeholder="Name" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                <input name="Phone" type="phone" class="form-control" required="" placeholder="Enter Phone No" />
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon v-align-t"><i class="fa fa-pencil"></i></span>
                                                <textarea name="message" rows="5" class="form-control " required="" placeholder="Message" columns="5"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Submit  <i class="fa fa-angle-double-right"></i></button>

                                    </div>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>


            <div id="enquiryMailModal" style={{ display: "none" }} className="footer_fixed modal">
                <div className="modal-content">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="contact__page_card">
                        <h4>Mail Us On</h4>
                        <div className="p-a30 bg-gray">
                            <form className="cons-contact-form" method="post" action="phpmailer/mail.php">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-user"></i></span>
                                                <input name="username" type="text" required className="form-control" placeholder="Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                                                <input name="Mail" type="email" className="form-control" required placeholder="Mail Us" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <button name="submit" type="submit" value="Submit" className="site-button m-r15">Submit <i className="fa fa-angle-double-right"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CallToAction