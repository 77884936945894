import React from 'react'
import { Link } from 'react-router-dom'
import sah_logo from "../assets/images/sah_logo.png"
import "../assets/style/footer.css"
import logo from "../assets/images/logo.png"
import { RiArrowRightDoubleLine } from 'react-icons/ri';
import { FaFacebookSquare, FaInstagram, FaInstagramSquare, FaPhoneAlt } from 'react-icons/fa';
import { MdLocationPin, MdOutlineMailOutline } from 'react-icons/md';
import { AiFillTwitterSquare } from 'react-icons/ai';

const Footer = () => {
    return (
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-12 footer_list" >
                        <h4>About us</h4>
                        <p>At SAH Education Consultant and Recruiting Ltd, we are dedicated to guiding students and professionals towards achieving their academic and career aspirations. </p>
                    </div>
                    <div className="col-lg-3 col-sm-12 footer_list">

                        <div className="row">
                            <div className="col-lg-4 col-sm-12"></div>
                            <div className="col-lg-8 col-sm-12">
                                <h4>Menu</h4>
                                <ul class="lab-ul">
                                    {/* <li>
                                        <RiArrowRightDoubleLine />  <Link to="/">Home</Link>
                                    </li> */}
                                    <li>
                                        <RiArrowRightDoubleLine />   <Link to="/about">About</Link>
                                    </li>
                                    <li>
                                        <RiArrowRightDoubleLine />   <Link to="/contact">Contact</Link>
                                    </li>

                                    <li>
                                        <RiArrowRightDoubleLine />  <Link to='/study-abroad/uk'>Study in UK</Link>
                                    </li>
                                    <li>
                                        <RiArrowRightDoubleLine />   <Link to='/study-abroad/australia'>Study in Australia</Link>
                                    </li>
                                    <li>
                                        <RiArrowRightDoubleLine />   <Link to='/study-abroad/canada'>Study in Canada</Link>
                                    </li>
                                    <li>
                                        <RiArrowRightDoubleLine />   <Link to='/study-abroad/usa'>Study in USA</Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-12">
                        <div className="row">

                            <div className="col-lg-9">

                                <ul class="lab-ul">
                                    <li>
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-2"><MdLocationPin /></div>
                                            <div className="col-lg-10 col-sm-12">Stuart House, St John's St, East Wing, Peterborough PE1 5DD, United Kingdom</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-2"><FaPhoneAlt /></div>
                                            <div className="col-lg-10 col-sm-12"> +447508655533</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-2"><MdOutlineMailOutline /></div>
                                            <div className="col-lg-10 col-sm-12"> info@saheducation.co.uk</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    {/* <div className="row">
                            <div className="col-lg-2 col-sm-12"></div>
                            <div className="col-lg-4 col-sm-12">
                                <h4>Menu</h4>
                                <ul>
                                    {/* <li>
                                        <RiArrowRightDoubleLine />  <Link to="/">Home</Link>
                                    </li> */}
                    {/* <li>
                        <RiArrowRightDoubleLine />   <Link to="/about">About</Link>
                    </li>
                    <li>
                        <RiArrowRightDoubleLine />   <Link to="/contact">Contact</Link>
                    </li>

                    <li>
                        <RiArrowRightDoubleLine />  <Link to='/study-abroad/uk'>Study in UK</Link>
                    </li>
                    <li>
                        <RiArrowRightDoubleLine />   <Link to='/study-abroad/australia'>Study in Australia</Link>
                    </li>
                    <li>
                        <RiArrowRightDoubleLine />   <Link to='/study-abroad/canada'>Study in Canada</Link>
                    </li>
                    <li>
                        <RiArrowRightDoubleLine />   <Link to='/study-abroad/usa'>Study in USA</Link>
                    </li> */}



                    <div className="col-lg-3 col-sm-12 footer_list">

                        <div className="row">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-6">
                                <img src={logo} alt="" />
                                <div className="social_media_icons">
                                    <ul className='social_media_grp'>
                                        <li><FaFacebookSquare fontSize="20px" /></li>
                                        <li><FaInstagramSquare fontSize="20px" /></li>
                                        <li><AiFillTwitterSquare fontSize="20px" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                    <p className="text-center">
                        Copyright © 2024 Sah  Education. Designed by <Link to="https://csgroups.in/" style={{ color: "#fff" }}> CS Groups</Link>

                    </p>
                </div>
            </div >
        </section >
    )
}

export default Footer