import React from 'react'
import call from "../assets/images/contact/call.png";
import email from "../assets/images/contact/email.png"
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact | Sah Education</title>
                <link rel="canonical" href="https://saheducation.co.uk/about" />
            </Helmet>

            <div className="study_abroad pb-80">
                <h3>Contact Us</h3>
            </div>


            <div className="contact_section pb-80">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-sm-12">
                            <ul className='contact_desc'>
                                <li>
                                    <img src={email} alt="" style={{ width: "64px" }} />
                                    <p>info@saheducation.co.uk</p>


                                </li>

                                <li>
                                    <img src={call} alt="" style={{ width: "64px" }} />
                                    <p>7508655533</p>
                                </li>


                                <li>
                                    <h4>Follow us on</h4>
                                    <div className="social_media">
                                        <p><FaFacebook fontSize="20px" /></p>
                                        <p><FaInstagramSquare fontSize="20px" /></p>
                                    </div>

                                </li>

                            </ul>
                            {/* 
                            <h4>follow us on</h4>
                                    <ul className="social_media">
                                        <li><FaFacebook fontSize="20px" /></li>
                                        <li><FaInstagramSquare fontSize="20px" /></li>
                                        <li></li>
                                    </ul> */}


                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <h3>Feel free to reach out</h3>
                            <div className="contact_card">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" id="name" name="name" placeholder="Enter your name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" placeholder="Enter your email" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Mobile Number</label>
                                        <input type="number" id="number" name="number" placeholder="Enter your number" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea name="" id="message" rows="5" column=""></textarea>
                                    </div>
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='pb-80'>
                <div className="container">
                    <div className="row">
                        <h3>Location Map</h3>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19395.735134942242!2d-0.25554339365508827!3d52.579251447915965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877f182585fb8d5%3A0x592eeab1e708c676!2sSah%20Education%20Consultant%20And%20Recruiting%20Limited!5e0!3m2!1sen!2sus!4v1725108819434!5m2!1sen!2sus" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact